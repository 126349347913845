import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('form', { static: false }) public userFrm: NgForm;

  name: string;
  email: string;
  phoneNumber: string;
  message: string;

  constructor(public db: AngularFirestore) {}

  ngOnInit() {}

  onSubmit(): void {
    if (!this.userFrm.valid) {
      return;
    }

    this.db
      .collection('contacts')
      .add({
        name: this.name,
        email: this.email,
        phoneNumber: this.phoneNumber,
        message: this.message
      })
      .then(res => {
        this.userFrm.resetForm();
      });
  }
}
