export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyC5_Ql_jtktSgwAJN-x7f7ziBYnsWfBvYI',
    authDomain: 'right-choice-website-87969.firebaseapp.com',
    databaseURL: 'https://right-choice-website-87969.firebaseio.com',
    projectId: 'right-choice-website-87969',
    storageBucket: 'right-choice-website-87969.appspot.com',
    messagingSenderId: '986037090509',
    appId: '1:986037090509:web:5428559bd99e1c52285184'
  }
};
